import type { ElementRef, SVGProps } from "react";
import { twMerge } from "tailwind-merge";

export const QuinoIcon = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 116 111"
      fill="none"
      {...props}
      className={twMerge("h-[111px] w-[116px]", props.className)}
    >
      <path
        d="M33.3125 76.0486C36.1169 78.7231 37.6598 82.331 37.6598 86.2116H43.9149C43.9149 80.59 41.6735 75.3564 37.6181 71.4863L33.3125 76.0486Z"
        fill="#33124D"
      />
      <path
        d="M24.7617 56.2716V62.5645C35.8853 62.554 44.9343 53.4398 44.9343 42.249H38.6793C38.6897 49.9787 32.445 56.2716 24.7617 56.2716Z"
        fill="#33124D"
      />
      <path
        d="M98.4007 18.9625H89.7896C89.6853 8.48487 81.1888 0 70.7637 0C64.0291 0 58.1076 3.54498 54.7194 8.87293C51.4981 4.28963 46.2021 1.27955 40.2076 1.27955C30.8042 1.27955 23.1 8.67365 22.5058 17.9871H22.4849C22.4849 18.05 22.4953 18.1025 22.4953 18.1654C22.4745 18.501 22.4432 18.8261 22.4432 19.1618C22.4432 19.3715 22.4432 19.5813 22.4536 19.791C15.0101 23.9968 10.3084 31.9677 10.3084 40.6833C10.3084 41.5643 10.3605 42.4558 10.4543 43.3473C4.39732 48.7801 0.957031 56.4679 0.957031 64.7011C0.957031 75.0528 6.36767 84.4082 15.1873 89.5264C16.1673 100.507 25.3727 109.15 36.538 109.15C44.2109 109.15 50.9559 105.091 54.7507 98.9657C58.681 105.835 66.0307 110.44 74.4333 110.44C86.0157 110.44 95.6172 101.651 96.9725 90.3549H98.4007C108.117 90.3549 115.998 82.4259 115.998 72.651V36.6769C116.009 26.881 108.127 18.9625 98.4007 18.9625ZM51.717 87.9846L51.6961 88.3727C51.2896 96.4905 44.6279 102.857 36.538 102.857C28.177 102.857 21.359 96.008 21.359 87.5966V87.5756C21.3694 82.9503 23.6317 78.5978 27.4056 75.9443L23.8193 70.7841C19.8161 73.595 17.0013 77.7273 15.7816 82.3735C10.4126 78.1783 7.21211 71.7386 7.21211 64.7011C7.21211 57.7894 10.3188 51.3707 15.7399 47.0915L17.2411 45.9064L16.8866 44.029C16.6781 42.9173 16.5739 41.7951 16.5739 40.6833C16.5739 34.7156 19.5763 29.2198 24.4136 25.979C26.5716 30.2791 30.4184 33.6668 35.1932 35.0722L36.955 29.031C32.1803 27.6152 28.8338 23.1577 28.7609 18.1654C29.2613 12.2396 34.2028 7.5724 40.2181 7.5724C46.567 7.5724 51.7274 12.764 51.7274 19.1513V87.9846H51.717ZM88.6115 77.507H63.9561V71.2142H88.6115V77.507ZM106.887 61.5127H63.9561V55.2198H106.887V61.5127ZM106.887 45.403H63.9561V39.1101H106.887V45.403Z"
        fill="#33124D"
      />
    </svg>
  );
};

