"use client";
// @ts-expect-error squircle library is misconfigured on the authors side and throwing a TS exception
import { Squircle } from "corner-smoothing";

const SquircleWrapper = ({cornerRadius=0, borderWidth=0, style, children,}: {cornerRadius?: number, borderWidth?: number, style?:React.CSSProperties, children: JSX.Element}) => {
  return (
    <Squircle style={style} cornerRadius={cornerRadius} cornerSmoothing={0.6} borderWidth={borderWidth}>
        {children}
    </Squircle>
  )
}

export default SquircleWrapper