import { type ElementRef, type SVGProps } from "react";

const VerticalLine = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="22" viewBox="0 0 2 22" fill="none">
      <path d="M1 0.75V21.25" stroke="#CCC1D5" />
    </svg>
  );
};

const ArrowNorthEast = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M12.0001 3.33325C12.1769 3.33325 12.3465 3.40349 12.4715 3.52851C12.5965 3.65354 12.6668 3.82311 12.6668 3.99992V9.33325C12.6668 9.51006 12.5965 9.67963 12.4715 9.80466C12.3465 9.92968 12.1769 9.99992 12.0001 9.99992C11.8233 9.99992 11.6537 9.92968 11.5287 9.80466C11.4037 9.67963 11.3335 9.51006 11.3335 9.33325V5.60925L4.70012 12.2426C4.57439 12.364 4.40598 12.4312 4.23119 12.4297C4.05639 12.4282 3.88918 12.3581 3.76557 12.2345C3.64197 12.1109 3.57186 11.9437 3.57034 11.7689C3.56882 11.5941 3.63601 11.4257 3.75745 11.2999L10.3908 4.66659H6.66679C6.48998 4.66659 6.32041 4.59635 6.19538 4.47132C6.07036 4.3463 6.00012 4.17673 6.00012 3.99992C6.00012 3.82311 6.07036 3.65354 6.19538 3.52851C6.32041 3.40349 6.48998 3.33325 6.66679 3.33325H12.0001Z"
        fill="white"
      />
    </svg>
  );
};

const HamburgerIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M26.6667 23.9999C27.0065 24.0003 27.3334 24.1304 27.5805 24.3637C27.8276 24.597 27.9763 24.9159 27.9962 25.2551C28.0161 25.5944 27.9058 25.9284 27.6876 26.189C27.4695 26.4496 27.1601 26.6171 26.8227 26.6573L26.6667 26.6666H5.33333C4.99349 26.6662 4.66662 26.5361 4.41951 26.3028C4.17239 26.0695 4.02369 25.7506 4.00377 25.4114C3.98385 25.0721 4.09423 24.7381 4.31235 24.4775C4.53047 24.2169 4.83987 24.0494 5.17733 24.0093L5.33333 23.9999H26.6667ZM26.6667 14.6666C27.0203 14.6666 27.3594 14.8071 27.6095 15.0571C27.8595 15.3072 28 15.6463 28 15.9999C28 16.3535 27.8595 16.6927 27.6095 16.9427C27.3594 17.1928 27.0203 17.3333 26.6667 17.3333H5.33333C4.97971 17.3333 4.64057 17.1928 4.39052 16.9427C4.14048 16.6927 4 16.3535 4 15.9999C4 15.6463 4.14048 15.3072 4.39052 15.0571C4.64057 14.8071 4.97971 14.6666 5.33333 14.6666H26.6667ZM26.6667 5.33325C27.0203 5.33325 27.3594 5.47373 27.6095 5.72378C27.8595 5.97383 28 6.31296 28 6.66659C28 7.02021 27.8595 7.35935 27.6095 7.60939C27.3594 7.85944 27.0203 7.99992 26.6667 7.99992H5.33333C4.97971 7.99992 4.64057 7.85944 4.39052 7.60939C4.14048 7.35935 4 7.02021 4 6.66659C4 6.31296 4.14048 5.97383 4.39052 5.72378C4.64057 5.47373 4.97971 5.33325 5.33333 5.33325H26.6667Z"
        fill="#4F3663"
      />
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9991 17.8854L23.5418 25.428C23.7932 25.6709 24.1301 25.8053 24.4796 25.8023C24.8292 25.7992 25.1637 25.659 25.4109 25.4118C25.6581 25.1646 25.7983 24.8302 25.8013 24.4806C25.8044 24.131 25.67 23.7942 25.4271 23.5427L17.8844 16L25.4271 8.45737C25.67 8.2059 25.8044 7.8691 25.8013 7.5195C25.7983 7.16991 25.6581 6.83549 25.4109 6.58828C25.1637 6.34107 24.8292 6.20084 24.4796 6.1978C24.1301 6.19477 23.7932 6.32916 23.5418 6.57204L15.9991 14.1147L8.45645 6.57204C8.20385 6.33516 7.869 6.20586 7.52275 6.21148C7.17651 6.2171 6.84604 6.35721 6.60126 6.60216C6.35648 6.84711 6.21661 7.17768 6.21123 7.52393C6.20585 7.87018 6.33539 8.20494 6.57245 8.45737L14.1138 16L6.57111 23.5427C6.44377 23.6657 6.34219 23.8128 6.27231 23.9755C6.20243 24.1382 6.16565 24.3131 6.16411 24.4902C6.16257 24.6672 6.19631 24.8428 6.26335 25.0066C6.33039 25.1705 6.4294 25.3194 6.55459 25.4446C6.67978 25.5698 6.82865 25.6688 6.99251 25.7358C7.15637 25.8028 7.33194 25.8366 7.50898 25.835C7.68602 25.8335 7.86098 25.7967 8.02365 25.7268C8.18632 25.657 8.33345 25.5554 8.45645 25.428L15.9991 17.8854Z"
        fill="#4F3663"
      />
    </svg>
  );
};

export { VerticalLine, ArrowNorthEast, HamburgerIcon, CloseIcon };
